<template>
  <ProfileCard :tab="tab">
    <template v-slot:contactInformationTabName>
      Basic Information
    </template>

    <template v-slot:preTabs>
      <v-tab style="justify-content: left" v-if="$kawasaki.isRacer()">
        <v-icon left>
          mdi-flag-checkered
        </v-icon>
        <v-badge icon="mdi-exclamation-thick" color="error" v-if="validRacerForm == false">
          <span class="d-none d-sm-flex"
            ><slot name="contactTabName"
              >{{ $i18n.translate("Racer") }} {{ $i18n.translate("Information") }}</slot
            ></span
          >
        </v-badge>
        <span v-else>
          <span class="d-none d-sm-flex"
            ><slot name="contactTabName"
              >{{ $i18n.translate("Racer") }} {{ $i18n.translate("Information") }}</slot
            ></span
          >
        </span>
      </v-tab>
      <v-tab style="justify-content: left" v-if="$kawasaki.isRacer()">
        <v-icon left>
          mdi-atv
        </v-icon>
        <v-badge icon="mdi-exclamation-thick" color="error" v-if="validVehiclesForm == false">
          <span class="d-none d-sm-flex"
            ><slot name="contactTabName"
              >{{ $i18n.translate("vehicle") }} {{ $i18n.translate("management") }}</slot
            ></span
          >
        </v-badge>
        <span v-else>
          <span class="d-none d-sm-flex"
            ><slot name="contactTabName"
              >{{ $i18n.translate("vehicle") }} {{ $i18n.translate("management") }}</slot
            ></span
          >
        </span>
      </v-tab>

      <v-tab style="justify-content: left" v-if="$kawasaki.isRacer()">
        <v-icon left>
          mdi-transfer
        </v-icon>
        <span class="d-none d-sm-flex">
          <slot name="contactTabName">{{ $i18n.translate("Vehicle") }} {{ $i18n.translate("Transfers") }}</slot>
        </span>
      </v-tab>
    </template>

    <template v-slot:organization-tab v-if="$kawasaki.isSalesperson()">
      <v-card flat>
        <v-container>
          <v-row>
            <v-col class="pb-0">
              <v-alert border="top" type="info">
                Please use the table below to manage your dealerships.
              </v-alert>
            </v-col>
          </v-row>
          <RelatedDealersTable
            :value.sync="organizations"
            :loading="loading"
            :isEnrolling="false"
            ref="relatedDealersTable"
            @changeErrorMessage="handleErrorsChange"
          >
            <template v-slot:header>&nbsp;</template>
            <template v-slot:alerts>
              <AlertDisplay ref="alertRef" durationOption="long" />
            </template>
            <template v-slot:submit>
              <div class="mt-4 text-left">
                <v-btn
                  color="primary"
                  :loading="loading"
                  :disabled="!dealerTableHasChanges || organizations.existing.length == 0"
                  @click="handleSubmitDealers"
                >
                  {{ $i18n.translate("Update") }}
                </v-btn>
              </div>
            </template>
          </RelatedDealersTable>
        </v-container>
      </v-card>
    </template>

    <template v-slot:preTabItems>
      <v-tab-item :eager="true" v-if="$kawasaki.isRacer()">
        <ProfileRacerTab v-model="validRacerForm" @onLoad="loaded++"> </ProfileRacerTab>
      </v-tab-item>
      <v-tab-item :eager="true" v-if="$kawasaki.isRacer()">
        <v-card flat>
          <v-container>
            <v-row>
              <v-col class="pb-0">
                <v-alert border="top" type="info">
                  Please use the table below to manage your vehicles.
                </v-alert>
              </v-col>
            </v-row>
            <VinTableManager :loading="loading" :value.sync="vehicles">
              <template v-slot:header>&nbsp;</template>
            </VinTableManager>
          </v-container>
        </v-card>
      </v-tab-item>
      <v-tab-item v-if="$kawasaki.isRacer()">
        <v-card flat>
          <v-container>
            <v-row v-if="hasPendingTransfers">
              <v-col class="pb-0">
                <v-alert border="top" type="info">
                  There are pending VIN transfer requests awaiting your review. Approve or reject them to proceed.
                </v-alert>
              </v-col>
            </v-row>
            <VinTransferManagement @update-has-data="hasPendingTransfers = $event" ref="vinTransferManagement">
              <template v-slot:header>&nbsp;</template>
            </VinTransferManagement>
          </v-container>
        </v-card>
      </v-tab-item>
    </template>
  </ProfileCard>
</template>

<script>
import AlertDisplay from "../../../gapp-components/components/display/AlertDisplay.vue";
import RelatedDealersTable from "@/views/common/table/RelatedDealersTable.vue";
import ProfileCard from "@/gapp-components/components/common/profile/ProfileCard.vue";
import ProfileRacerTab from "@/views/common/profile/ProfileRacerTab.vue";
import { mapGetters } from "vuex";
import VinTableManager from "@/views/common/table/VinTableManager.vue";
import VinTransferManagement from "@/views/admin/VinTransferManagement.vue";

export default {
  name: "Profile",
  metaInfo: {
    title: "Profile"
  },
  components: {
    ProfileRacerTab,
    ProfileCard,
    VinTableManager,
    VinTransferManagement,
    RelatedDealersTable,
    AlertDisplay
  },
  data() {
    return {
      loaded: 0,
      tab: 0,
      missing: false,
      firstTime: true,
      loading: false,
      errors: {},
      validRacerForm: false,
      vehicles: {
        existing: [],
        delete: [],
        refreshRequired: false
      },
      hasPendingTransfers: false,
      organizations: {
        existing: [],
        delete: []
      },
      participantTypeKey: "300",
      participant: {}
    };
  },
  mounted() {
    if (this.$route.query && this.$route.query.tab && this.$route.query.tab == "vehicles") {
      this.tab = 1;
    } else if (this.$route.query && this.$route.query.tab && this.$route.query.tab == "vehiclesTransfer") {
      this.tab = 2;
    }
  },
  computed: {
    ...mapGetters(["selectedParticipant"]),
    validVehiclesForm() {
      return this.vehicles.existing?.length > 0;
    },
    dealerTableHasChanges() {
      return this.organizations.existing.some(org => org.dirty == true) || this.organizations.delete.length > 0;
    }
  },
  watch: {
    "vehicles.refreshRequired": function(newVal) {
      if (newVal === true) {
        if (this.$refs.vinTransferManagement) {
          this.$refs.vinTransferManagement.getData();
          this.vehicles.refreshRequired = false;
        }
      }
    }
  },
  methods: {
    async handleSubmitDealers() {
      this.loading = true;
      try {
        const { success, participant } = await this.$kawasaki.updateSalespersonDealers(
          this.organizations,
          this.participantTypeKey
        );
        if (success) {
          this.$refs.alertRef.success(`Successfully updated.`);
          this.participant = participant;
          this.$refs.relatedDealersTable.resetOrganizations();
        }
      } catch (error) {
        this.errors = this.$api.getErrorsFromResponse(error);
        this.$refs.alertRef.error(this.errors.message);
      } finally {
        this.loading = false;
      }
    },
    handleErrorsChange(errors) {
      this.$refs.alertRef.error(errors.message);
    }
  }
};
</script>
