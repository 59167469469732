<template>
  <div>
    <slot name="header">
      <h3>{{ verbiageHeader }}</h3>
      <p>{{ verbiageDescription }}</p>
    </slot>
    <slot name="alerts">
      <ApiError class="mt-4" :errors="errors"></ApiError>
    </slot>
    <v-card shaped>
      <v-data-table
        item-key="subId"
        :items="organizations.existing"
        :headers="getDynamicHeaders"
        :no-data-text="noDataText"
        disable-sort
        disable-filtering
        disable-pagination
        hide-default-footer
        :loading="isLoading"
      >
        <template v-slot:item.organizationType="{ item }">
          {{ item && item.organizationType ? item.organizationType.organizationTypeKey : "" }}
        </template>
        <template v-slot:top>
          <v-card shaped dark>
            <v-container>
              <v-row>
                <v-col cols="12" md="4">
                  <v-select
                    dense
                    outlined
                    hide-details
                    :items="allowedJobTitles"
                    item-text="text"
                    :item-value="job => job"
                    v-model="selectedJobTitle"
                    label="Select Job Title"
                    class="required"
                    :disabled="isLoading"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" md="4">
                  <OrganizationField
                    v-model="form.organization"
                    :showKey="true"
                    :label="getLabel('Select')"
                    :searchFilterJson="searchFilter"
                    dense
                    outlined
                    hide-details
                    :disabled="!selectedJobTitle"
                  ></OrganizationField>
                </v-col>
                <v-col cols="12" md="4" class="text-right">
                  <v-btn dense color="primary" :disabled="isLoading || !isFormValid" @click="addOrganization">
                    <v-icon small>mdi-plus</v-icon> {{ getLabel("Add") }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </template>
        <template v-slot:item.address="{ item }">
          <div v-if="item && item.address">
            {{ item.address.fullAddress || "" }}<br />
            {{ item.phoneNumber1 | toPhoneNumber }}
          </div>
          <div v-else>
            No address information
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="my-2" v-bind="attrs" v-on="on" @click="removeOrganization(item)">
                mdi-delete
              </v-icon>
            </template>
            <span>Remove</span>
          </v-tooltip>
          <br />

          <v-tooltip v-if="item" bottom>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <v-icon v-if="!item.isPrimary" class="my-2" @click="makePrimary(item)">
                  mdi-star-outline
                </v-icon>
                <v-icon v-else class="my-2" color="primary" @click="makePrimary(item)">
                  mdi-star
                </v-icon>
              </span>
            </template>
            <template v-slot:default>
              {{ item.isPrimary ? "Primary" : "Make primary" }}
            </template>
          </v-tooltip>
        </template>
        <template v-slot:item.jobTitle="{ item }">
          <div v-if="item" class="required">
            <span>{{ item.jobTitle }}</span>
          </div>
        </template>
      </v-data-table>
    </v-card>
    <div class="mt-4 text-right">
      <slot name="submit"></slot>
    </div>
  </div>
</template>
<script>
import ApiError from "@/gapp-components/components/display/ApiError.vue";
import OrganizationField from "@/gapp-components/components/fields/OrganizationField.vue";
import { mapGetters } from "vuex";

export default {
  name: "RelatedDealersTable",
  props: {
    value: {
      type: Object,
      default: () => ({ existing: [], delete: [] })
    },
    loading: Boolean,
    hasError: {
      type: Boolean,
      default: false
    },
    participantTypeKeyForOrganization: String,
    isEnrolling: { type: Boolean, default: false }
  },
  components: { ApiError, OrganizationField },
  data() {
    return {
      organizations: this.value,
      participant: {},
      jobTitles: [
        {
          text: "AKM Regional Sales Director",
          value: "SD",
          participantTypeKey: "210",
          organizationType: "REGION",
          forceOrganization: false
        },
        {
          text: "AKM District Manager",
          value: "DM",
          participantTypeKey: "220",
          organizationType: "DISTRICT",
          forceOrganization: false
        },
        {
          text: "AKM Staff",
          value: "KS",
          participantTypeKey: "230",
          organizationType: "COMPANY",
          forceOrganization: true,
          organizationKey: "COMPANY-1"
        },
        {
          text: "Dealer Principal",
          value: "DP",
          participantTypeKey: "240",
          organizationType: "DEALER",
          forceOrganization: false
        },
        {
          text: "Dealership",
          value: "DR",
          participantTypeKey: "240",
          organizationType: "DEALER",
          forceOrganization: false
        },
        { text: "Salesperson", value: "SP", participantTypeKey: "300", organizationType: "DEALER" },
        { text: "Sales Manager", value: "SM", participantTypeKey: "300", organizationType: "DEALER" },
        { text: "Tech User", value: "TU", participantTypeKey: "300", organizationType: "DEALER" },
        { text: "Accessories Manager", value: "AM", participantTypeKey: "300", organizationType: "DEALER" },
        { text: "Accessories Staff", value: "AS", participantTypeKey: "300", organizationType: "DEALER" },
        { text: "F&I Manager", value: "FM", participantTypeKey: "300", organizationType: "DEALER" },
        { text: "F&I Staff", value: "FS", participantTypeKey: "300", organizationType: "DEALER" },
        { text: "Service Manager", value: "VM", participantTypeKey: "300", organizationType: "DEALER" },
        { text: "Service Staff", value: "SS", participantTypeKey: "300", organizationType: "DEALER" },
        { text: "Other", value: "OT", participantTypeKey: "300", organizationType: "DEALER" }
      ],
      searchFilter: {
        organizationTypeKey: "DEALER"
      },
      verbiageHeader: "Dealer Information",
      verbiageDescription: "Please enter your Dealer information.",
      requiredOrganizationType: "Dealer",
      organizationSelectionRequired: false,
      originalJobTitle: "",
      errors: {},
      form: {
        organization: null
      },
      selectedJobTitle: ""
    };
  },
  computed: {
    ...mapGetters(["selectedParticipant", "selectedProgram"]),
    isLoading: {
      get() {
        return this.loading;
      },
      set(value) {
        this.$emit("update:loading", value);
      }
    },
    isFormValid() {
      if (this.organizationSelectionRequired) {
        return this.form.organization && this.selectedJobTitle;
      }
      return this.selectedJobTitle;
    },
    noDataText() {
      const label = this.getDynamicLabel();
      return `Add at least 1 ${label} before continuing`;
    },

    isEditing() {
      const needsToJoinSalesProgram = this.$route.query.needsToJoinSalesProgram;
      return needsToJoinSalesProgram || !this.isEnrolling;
    },

    allowedJobTitles() {
      if (this.isEditing) {
        const key = this.selectedParticipant.participantType.participantTypeKey;
        return this.jobTitles.filter(jt => jt.participantTypeKey === key);
      }

      if (this.organizations.existing && this.organizations.existing.length > 0) {
        const org = this.organizations.existing[0];
        const orgJobTitle = this.jobTitles.find(jt => jt.text === org.jobTitle);
        if (orgJobTitle && orgJobTitle.participantTypeKey) {
          const key = orgJobTitle.participantTypeKey;
          return this.jobTitles.filter(jt => jt.participantTypeKey === key);
        }
      }

      if (this.selectedJobTitle && this.selectedJobTitle.participantTypeKey) {
        const key = this.selectedJobTitle.participantTypeKey;
        return this.jobTitles.filter(jt => jt.participantTypeKey === key);
      }

      if (this.participantTypeKeyForOrganization) {
        const key = this.participantTypeKeyForOrganization;
        return this.jobTitles.filter(jt => jt.participantTypeKey === key);
      }
      return this.jobTitles;
    },

    getDynamicHeaders() {
      const label = this.getDynamicLabel();
      return [
        { value: "organizationType", text: `${label} Type` },
        { value: "legacyOrganizationKey", text: `${label} Code` },
        { value: "name", text: `${label} Name` },
        { value: "address", text: "Location" },
        { value: "jobTitle", text: "Job Title" },
        { value: "actions", text: "" }
      ];
    }
  },
  watch: {
    value(newVal) {
      this.organizations = newVal;
    },
    organizations: {
      deep: true,
      handler(newVal) {
        this.$emit("update:value", newVal);
      }
    },
    participantTypeKeyForOrganization: {
      handler(newVal) {
        this.$emit("update:participantTypeKeyForOrganization", newVal);
      }
    },
    selectedJobTitle(newJobTitle) {
      this.form.organization = null;
      if (newJobTitle) {
        this.onJobTitleChange(newJobTitle);
      }
    }
  },
  methods: {
    setErrorState(isError) {
      this.$emit("update:hasError", isError);
      if (isError) {
        this.$emit("changeErrorMessage", this.errors);
      }
    },
    getDynamicLabel() {
      const labels = {
        DEALER: "Dealer",
        REGION: "Region",
        DISTRICT: "District",
        COMPANY: "Company"
      };
      return labels[this.requiredOrganizationType] || "Dealer";
    },
    getLabel(action) {
      const label = this.getDynamicLabel();
      return `${action} ${label}`;
    },
    existOrganization(organization) {
      const exists = this.organizations.existing.some(
        org => org.jobTitle === organization.jobTitle && org.organizationKey === organization.organizationKey
      );

      if (exists) {
        this.errors = { message: "This organization with the same job title already exists." };
        this.selectedJobTitle = null;
        this.setErrorState(true);
        return true;
      }
      this.errors = {};
      this.setErrorState(false);
      return false;
    },

    removeOrganization(organization) {
      const index = this.organizations.existing.findIndex(org => org.subId === organization.subId);
      if (index !== -1) {
        this.organizations.existing.splice(index, 1);
        this.organizations.delete.push(organization);
      }
      if (!this.isEditing) {
        if (this.organizations.existing.length === 0) {
          this.selectedJobTitle = null;
          this.$emit("update:participantTypeKeyForOrganization", null);
        } else if (this.selectedJobTitle) {
          const exists = this.organizations.existing.some(org => org.jobTitle === this.selectedJobTitle.text);
          if (!exists) {
            this.selectedJobTitle = null;
            this.$emit("update:participantTypeKeyForOrganization", null);
          }
        }
      }
    },

    makePrimary(organization) {
      this.organizations.existing.forEach(org => {
        org.isPrimary = org === organization;
        org.dirty = true;
      });
    },

    onJobTitleChange(newJobTitle) {
      this.form.organization = null;
      this.$emit("update:participantTypeKeyForOrganization", newJobTitle.participantTypeKey);
      this.organizationSelectionRequired = !newJobTitle.forceOrganization;

      if (newJobTitle.forceOrganization) {
        this.form.organization = {
          organizationKey: newJobTitle.organizationKey,
          organizationType: newJobTitle.organizationType
        };
        this.addForcedOrganization(newJobTitle);
      } else {
        this.form.organization = null;
        if (newJobTitle.organizationType) {
          this.searchFilter = { organizationTypeKey: newJobTitle.organizationType };
        } else {
          this.searchFilter = null;
        }
      }
      this.requiredOrganizationType = newJobTitle.organizationType || null;
      this.updateVerbiage();
    },
    updateVerbiage() {
      const label = this.getDynamicLabel();

      this.verbiageHeader = `${label} Information`;
      this.verbiageDescription = `Please select a Job Title, enter your Kawasaki ${label} Number, and click 'Add ${label}' to display your ${label} association.`;
    },
    createNewOrganization(baseOrganization) {
      return {
        ...baseOrganization,
        jobTitle: this.selectedJobTitle.text,
        isPrimary: this.organizations.existing.length === 0,
        relationshipId: null,
        dirty: true
      };
    },
    resetForm() {
      this.form.organization = null;
      this.selectedJobTitle = null;
      this.setErrorState(false);
    },
    resetOrganizations() {
      const primaryExists = this.organizations.existing.some(org => org.isPrimary);
      if (!primaryExists && this.organizations.existing.length > 0) {
        this.organizations.existing[0].isPrimary = true;
        this.organizations.existing[0].relationshipId = null;
      }
      this.organizations.existing = this.organizations.existing.map(org => {
        org.dirty = false;
        return org;
      });
      this.organizations.delete = [];
    },
    addOrganization() {
      const organizationInfo = this.form.organization;
      if (organizationInfo) {
        const newOrganization = this.createNewOrganization(organizationInfo);
        if (this.existOrganization(newOrganization)) {
          this.resetForm();
          return;
        }
        this.organizations.existing.push(newOrganization);
        this.form.organization = null;

        if (newOrganization.isPrimary) {
          this.$emit("participantTypeChanged", this.selectedJobTitle.participantTypeKey);
        }

        this.resetForm();
        this.errors = {};
      } else {
        this.errors = { message: "No organization selected." };
      }
    },
    addForcedOrganization(organization) {
      this.fetchOrganizationInfoByKey(organization.organizationKey).then(organizationInfo => {
        if (organizationInfo) {
          const newOrganization = this.createNewOrganization(organizationInfo);
          if (this.existOrganization(newOrganization)) {
            this.resetForm();
            return;
          }
          if (newOrganization.isPrimary) {
            this.$emit("participantTypeChanged", organization.participantTypeKey);
          }
          this.resetForm();
          this.organizations.existing.push(newOrganization);
          this.errors = {};
        }
      });
    },
    async fetchOrganizationInfoByKey(organizationKey) {
      this.isLoading = true;
      try {
        const searchForm = {
          organizationKey: organizationKey,
          programKey: this.selectedProgram.programKey,
          programGroupKey: this.selectedProgram.programGroup.programGroupKey
        };
        const { data } = await this.$api.post("/api/organizations/search", searchForm);
        if (data.content.length === 0) {
          this.errors = {
            message: `Organization not found for Key ${organizationKey}`,
            status: "404"
          };
          return null;
        }
        return data.content[0];
      } catch (error) {
        this.errors = this.$api.getErrorsFromResponse(error);
        return null;
      } finally {
        this.isLoading = false;
      }
    }
  },
  async mounted() {
    if (!this.isEnrolling) {
      this.isLoading = true;
      let subId = 1;
      let primaryOrganization = null;
      let participant = null;
      let primaryOrg = null;
      let primaryOrganizationType = null;

      try {
        const { data } = await this.$api.get(`/api/participants/${this.selectedParticipant.id}`);
        participant = data;
      } catch (error) {
        console.error("Error fetching participant:", error);
      }

      if (participant) {
        try {
          const { data } = await this.$api.getRelatedObject("organization", participant);
          primaryOrg = data;
          if (primaryOrg) {
            try {
              const { data } = await this.$api.getRelatedObject("organizationType", primaryOrg);
              primaryOrganizationType = data;
            } catch (error) {
              console.error("Error fetching organizationType:", error);
            }
            if (primaryOrganizationType) {
              primaryOrg.organizationType = primaryOrganizationType;
            }
          }
          this.participant = participant;

          primaryOrganization = {
            subId: subId++,
            ...primaryOrg,
            jobTitle: participant.customFieldValue16,
            isPrimary: true,
            relationshipId: null,
            dirty: false
          };
          this.$emit("participantTypeChanged", this.selectedParticipant.participantType.participantTypeKey);
        } catch (error) {
          console.error("Error fetching primary organización:", error);
        }
      }

      let { data: relatedOrganizations } = await this.$api.post("/api/relatedOrganizations/search", {
        participant: { id: this.selectedParticipant.id }
      });
      const relatedOrgs = relatedOrganizations.content.map(org => ({
        isPrimary: false,
        subId: subId++,
        ...org.relatedOrganization,
        jobTitle: org.description,
        relationshipId: org.id,
        dirty: false
      }));
      this.organizations.existing = [primaryOrganization, ...relatedOrgs].filter(
        org => org !== null && org !== undefined
      );
      if (!primaryOrganization) {
        this.makePrimary(this.organizations.existing[0]);
      }
      this.isLoading = false;
    }
  }
};
</script>
